import { popupCenter } from '@/util/Popup.js'
import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import DeveloperService from '@/services/developer.service'
import OauthService from '@/services/oauth.service'

export default {
  name: 'PluginHubspot',
  components: {
    HeaderTopDashboard,
  },
  data () {
    return {
      docTranslations: ['es', 'en', 'pt'],
      hubspot: {},
    }
  },
  computed: {},
  watch: {},
  created () {
    this.getPluginModulesAndIntegrations()
  },
  mounted () {},
  methods: {
    convertToSlug (text) {
      return text
        .toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^\w-]+/g, '')
    },
    getPluginModulesAndIntegrations () {
      const user = JSON.parse(localStorage.getItem('user'))
      const lang = this.$i18n.locale || user.lang
      const selectedTranslation = this.docTranslations.includes(lang) ? lang : 'en'
      const params = {
        lang: selectedTranslation,
      }
      DeveloperService.getPluginModulesAndIntegrations(params)
        .then(
          (response) => {
            this.hubspot = response.integrations.hubspot
          },
          (error) => {
            console.log(error)
          },
        )
    },
    disconnectService (itemName) {
      OauthService.disconnectService({}, this.convertToSlug(itemName))
        .then(
          (response) => {
            OauthService.successResponse(response.message)
            this.getPluginModulesAndIntegrations()
          },
          (error) => {
            OauthService.errorResponse(
              error.response.status,
              this.$t('Error al intentar desconectar aplicación'),
              this.$t(error.response.data.message),
            )
          },
        )
        .finally(() => {})
    },
    viewDoc (url, title) {
      popupCenter(url, title, 1000, 600)
    },
    connectIntegration (url) {
      window.location.href = url
    },
    disconnectIntegration (name) {
      this.disconnectService(name)
    },
  },
}
